import uuidv1 from 'uuid/v1';

// HEAD DATA
export const headData = {
  title: `Cyrus's Portfolio`, // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Cyrus Gracias',
  subtitle: `I'm a Frontend Web Developer`,
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'my-profile-pic.jpg',
  paragraphOne: `I completed my Bvoc Software Developers degree from St. Xavier's College, Mumbai (2020) with great enthusiasm, passion and good CGPA. My love for reading and learning has encouraged me to explore variety of computer science fields, build beautiful websites as well as solutions to my owns tech needs.`,
  paragraphTwo: `Sometimes I break things on my PC only to fix those and learn from it later, other times I fix my friends and neighbor's computer issues.`,
  paragraphThree: `Currently I am looking for a Frontend Web Developers Job.`,
  resume: 'https://docs.google.com/document/d/1gyx3fyGJ9VA1w9sX9BUrDre2VdnryGsaH56O7K2hBpI', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'mobinge.webp',
    title: 'Mobinge - Movie / Series Guide',
    info: 'Know your favorite movies and series, save to watchedlist or favorites list.',
    info2: 'Built using HTML5, CSS3, Bootstrap, JavaScript (ES6) and JQuery.',
    url: 'https://mobinge.netlify.app',
    repo: 'https://github.com/AwesomeIronman/mobinge', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'peace.webp',
    title: 'PEace CRM',
    info: 'A CRM built for small businesses with ease of use and productivity in mind.',
    info2: 'Built using PHP, Laravel, MySQL database and Bootstrap based dashboard.',
    url: 'https://youtu.be/g97IctQyERs',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'dorks-engine.webp',
    title: 'Dorks Engine',
    info: 'A frontend to help Google things more accurately to increase productivity.',
    info2: 'Built using ',
    url: 'https://dorks-engine.netlify.app',
    repo: 'https://github.com/AwesomeIronman/dorks-engine', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'cyrusgracias4u@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'facebook',
      url: 'https://www.facebook.com/cyrus.gracias/',
    },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/cyrus-gracias-b65171175/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/AwesomeIronman/',
    },
    {
      id: uuidv1(),
      name: 'twitter',
      url: 'https://twitter.com/CyrusGracias',
    }
  ],
};